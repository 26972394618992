<template>
    <div @click="onClick">
      <b-card
          no-body
          class=" wameed-card align-items-center justify-content-center py-5">
        <div class="settings-card-icon">
          <component :is="icon"/>
        </div>
        <h4 class="h-panel__title">
          {{ title }}
        </h4>
      </b-card>
    </div>
</template>

<script>


export default {
  props: {
    title: String,
    icon: String
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }

};
</script>

